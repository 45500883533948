@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Inter var", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.layout-container {
    @apply max-w-7xl mx-auto px-4;
}

.fc-h-event {
    @apply !bg-red-200 !border-red-300 pl-1;
}

.fc-event-title {
    @apply !text-gray-800;
}

.fc .fc-daygrid-day.fc-day-today {
    @apply !bg-gray-200;
}

.fc .fc-button-primary {
    @apply !bg-primary !border-primary;
}

.fc .fc-view-harness {
    @apply !bg-white;
}